import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {
  }

  /*canActivate(next: ActivatedRouteSnapshot): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }
    // Navigate to the login page with extras
    this.router.navigate(['/pages/login']);
    return false;
  }*/

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser) {
      const requiredRoles = route.data.roles as Array<string>;
      const userAuthorities = this.authService.getUserAuthorities();
      if (requiredRoles.some(role => userAuthorities.includes(role))) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    }

    this.router.navigate(['/pages/login']);
    return false;
  }

}
