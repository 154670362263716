import { Component, HostBinding } from '@angular/core';

import { UpgradableComponent } from 'theme/components/upgradable';
import {Router} from "@angular/router";
import {AuthService} from "@services/*";

@Component({
  selector: 'app-dashboard',
  styleUrls: ['../charts/charts.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent extends UpgradableComponent {
  @HostBinding('class.mdl-grid') public readonly mdlGrid = true;
  @HostBinding('class.mdl-grid--no-spacing') public readonly mdlGridNoSpacing = true;

  constructor(private router: Router, private authService: AuthService) {
    super();
    // console.log(localStorage.getItem('token'));

    /*if (!this.authService.isLoggedIn) {
      this.authService.logout();
    }*/


  }
  goToAttendance(){
    this.router.navigate(['/ui/time-tracking']);
  }
}
