<base-card>
  <base-card-title>
    <h2 class="mdl-card__title-text">Sliders</h2>
  </base-card-title>
  <base-card-body>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">DEFAULT SLIDER</span>
      </li>
      <li class="mdl-list__item">
        <input class="mdl-slider mdl-js-slider slider--colored-orange" type="range" min="0" max="100" value="0" tabindex="0">
      </li>
      <li class="mdl-list__item">
        <input class="mdl-slider mdl-js-slider slider--colored-green" type="range" min="0" max="100" value="0" tabindex="0">
      </li>
      <li class="mdl-list__item">
        <input class="mdl-slider mdl-js-slider slider--colored-purple" type="range" min="0" max="100" value="0" tabindex="0">
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">STARTING VALUE</span>
      </li>
      <li class="mdl-list__item">
        <input class="mdl-slider mdl-js-slider slider--colored-red" type="range" min="0" max="100" value="42" tabindex="0">
      </li>
      <li class="mdl-list__item">
        <input class="mdl-slider mdl-js-slider slider--colored-light-blue" type="range" min="0" max="100" value="12" tabindex="0">
      </li>
      <li class="mdl-list__item">
        <input class="mdl-slider mdl-js-slider slider--colored-teal" type="range" min="0" max="100" value="72" tabindex="0">
      </li>
    </ul>
  </base-card-body>
</base-card>
