<div class="mdl-layout mdl-js-layout mdl-layout--fixed-drawer mdl-layout--fixed-header has-drawer">
  <div class="mdl-layout__header">
    <base-page-top>
      <div class="mdl-layout-spacer"></div>

   <!--   <div class="mdl-textfield mdl-js-textfield mdl-textfield&#45;&#45;expandable search">
        <label class="mdl-button mdl-js-button mdl-button&#45;&#45;icon" for="search">
          <i class="material-icons">search</i>
        </label>

        <div class="mdl-textfield__expandable-holder">
          <input class="mdl-textfield__input" type="text" id="search"/>
          <label class="mdl-textfield__label" for="search">Enter your query...</label>
        </div>
      </div>-->

      <app-notification-menu></app-notification-menu>

      <app-message-menu></app-message-menu>

      <div class="avatar-dropdown" id="icon">
        <span>{{ user.username }}</span>
        <img src="assets/images/Icon_header.png">
      </div>
      <ul
        class="mdl-menu mdl-list mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect mdl-shadow--2dp account-dropdown"
        for="icon">
        <li class="mdl-list__item mdl-list__item--two-line">
            <span class="mdl-list__item-primary-content">
              <span class="material-icons mdl-list__item-avatar"></span>
              <span>{{ user.username }}</span>
              <span class="mdl-list__item-sub-title">{{ user.email }}</span>
          </span>
        </li>
        <li class="list__item--border-top"></li>
        <!--<li class="mdl-menu__item mdl-list__item">
            <span class="mdl-list__item-primary-content">
              <i class="material-icons mdl-list__item-icon">account_circle</i>
              My account
            </span>
        </li>
        <li class="mdl-menu__item mdl-list__item">
            <span class="mdl-list__item-primary-content">
              <i class="material-icons mdl-list__item-icon">check_box</i>
              My tasks
            </span>
          <span class="mdl-list__item-secondary-content">
              <span class="label background-color&#45;&#45;primary">3 new</span>
            </span>
        </li>
        <li class="mdl-menu__item mdl-list__item">
            <span class="mdl-list__item-primary-content">
              <i class="material-icons mdl-list__item-icon">perm_contact_calendar</i>
              My events
            </span>
        </li>
        <li class="list__item&#45;&#45;border-top"></li>
        <li class="mdl-menu__item mdl-list__item">
            <span class="mdl-list__item-primary-content">
              <i class="material-icons mdl-list__item-icon">settings</i>
              Settings
            </span>
        </li>-->
        <li class="mdl-menu__item mdl-list__item">
            <span class="mdl-list__item-primary-content" (click)="logout()">
              <i class="material-icons mdl-list__item-icon text-color--secondary">exit_to_app</i>
              Log out
            </span>
        </li>
      </ul>

      <button id="more" class="mdl-button mdl-js-button mdl-button--icon">
        <i class="material-icons">more_vert</i>
      </button>
      <ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu mdl-js-ripple-effect mdl-shadow--2dp settings-dropdown"
          for="more">
        <li class="mdl-menu__item" (click)="logout()">
          Salir
        </li>
       <!-- <a class="mdl-menu__item" href="https://github.com/CreativeIT/material-angular-dashboard/issues">
          Support
        </a>
        <li class="mdl-menu__item">
          F.A.Q.
        </li>-->
      </ul>
    </base-page-top>
  </div>
  <div class="mdl-layout__drawer">
    <app-sidebar></app-sidebar>
  </div>
  <main class="mdl-layout__content">
    <router-outlet></router-outlet>
  </main>
</div>
