<base-card>
  <base-card-title>
    <h2 class="mdl-card__title-text">Toggles</h2>
  </base-card-title>
  <base-card-body>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">RADIO BUTTON</span>
      </li>
      <li class="mdl-list__item">
        <label baseRadioButton name="options" value="1" color="green">Apple</label>
      </li>
      <li class="mdl-list__item">
        <label baseRadioButton name="options" value="2" color="orange">Orange</label>
      </li>
      <li class="mdl-list__item">
        <label baseRadioButton name="options" value="3" color="purple">Grapes</label>
      </li>
      <li class="mdl-list__item">
        <label baseRadioButton name="options" value="4" color="red" checked>Cherry</label>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">CHECKBOXES</span>
      </li>
      <li class="mdl-list__item">
        <label baseCheckbox color="green">Apple</label>
      </li>
      <li class="mdl-list__item">
        <label baseCheckbox color="orange">Orange</label>
      </li>
      <li class="mdl-list__item">
        <label baseCheckbox color="purple">Grapes</label>
      </li>
      <li class="mdl-list__item">
        <label baseCheckbox color="red" checked>Cherry</label>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">SWITCHES</span>
      </li>
      <li class="mdl-list__item">
        <label baseSwitch color="green" checked>Apple</label>
      </li>
      <li class="mdl-list__item">
        <label baseSwitch color="orange">Orange</label>
      </li>
      <li class="mdl-list__item">
        <label baseSwitch color="purple" checked>Grapes</label>
      </li>
      <li class="mdl-list__item">
        <label baseSwitch color="red">Cherry</label>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">ICON TOGGLE</span>
      </li>
      <li class="mdl-list__item">
        <label baseIconToggle color="red">format_bold</label>
        <label baseIconToggle color="orange" checked>format_italic</label>
        <label baseIconToggle color="green">format_underlined</label>
      </li>
    </ul>
  </base-card-body>
</base-card>
