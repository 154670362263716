import {Component, ElementRef, HostBinding, OnInit, Renderer2} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";
import {WeatherService} from "./weather.service";

@Component({
  selector: 'app-weather',
  styleUrls: ['./weather.component.scss'],
  templateUrl: './weather.component.html',
})
export class WeatherComponent implements OnInit {
  @HostBinding('class.weather') public readonly weather = true;

  weatherData: any;
  city: string = 'Buenos Aires';

  constructor(private http: HttpClient, private weatherService: WeatherService,
              private renderer: Renderer2,
              private el: ElementRef) {}

  ngOnInit(): void {
    this.getWeather();
  }

  getWeather(): void {
    this.weatherService.getWeather(this.city).subscribe(
      data => {
        this.weatherData = data;
        this.setWeatherIcon(this.weatherData.weather[0].icon);
        //console.log(this.weatherData);
      },
      error => {
        console.error('Error fetching weather data', error);
      }
    );
  }

  setWeatherIcon(iconCode: string): void {
    const iconUrl = `http://openweathermap.org/img/wn/${iconCode}@2x.png`;
    const weatherDescription = this.el.nativeElement.querySelector('.weather-description');
    this.renderer.setStyle(weatherDescription, '--weather-icon-url', `url(${iconUrl})`);
  }

}
