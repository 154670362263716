<div class="mdl-cell mdl-cell--4-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone">
  <app-toggles></app-toggles>
</div>

<div class="mdl-cell mdl-cell--4-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone">
  <app-progress-bars></app-progress-bars>
</div>

<div class="mdl-cell mdl-cell--4-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone">
  <app-chips></app-chips>
</div>

<div class="mdl-cell mdl-cell--4-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone">
  <app-sliders></app-sliders>
</div>

<div class="mdl-cell mdl-cell--4-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone">
  <app-tooltips></app-tooltips>
</div>

<div class="mdl-cell mdl-cell--4-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone">
  <app-badges></app-badges>
</div>

<div class="mdl-cell mdl-cell--3-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone mdl-cell--top">
  <base-card>
    <base-card-title>
      <h1 class="mdl-card__title-text">Simple avatar list</h1>
    </base-card-title>
    <base-card-body>
      <ul class="mdl-list">
        <li *ngFor="let item of data" class="mdl-list__item">
          <span class="mdl-list__item-primary-content">
            <div class="mdl-list__item-avatar">
              <img width="100%" src="assets/images/{{ item.image }}">
            </div>
            <span>{{ item.name }}</span>
          </span>
        </li>
      </ul>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--3-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone mdl-cell--top">
  <base-card>
    <base-card-title>
      <h1 class="mdl-card__title-text">Avatars and actions list</h1>
    </base-card-title>
    <base-card-body>
      <ul class="mdl-list">
        <li *ngFor="let item of data" class="mdl-list__item">
          <span class="mdl-list__item-primary-content">
            <div class="mdl-list__item-avatar">
              <img width="100%" src="assets/images/{{ item.image }}">
            </div>
            <span>{{ item.name }}</span>
          </span>
          <a class="mdl-list__item-secondary-action" href="#"><i class="material-icons">star</i></a>
        </li>
      </ul>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--3-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone mdl-cell--top">
  <base-card>
    <base-card-title>
      <h1 class="mdl-card__title-text">Avatars and controls list</h1>
    </base-card-title>
    <base-card-body>
      <ul class="mdl-list">
        <li *ngFor="let item of data; let i = index" [ngSwitch]="i" class="mdl-list__item">
          <span class="mdl-list__item-primary-content">
            <div class="mdl-list__item-avatar">
              <img width="100%" src="assets/images/{{ item.image }}">
            </div>
            <span>{{ item.name }}</span>
          </span>
          <span *ngSwitchCase="0" class="mdl-list__item-secondary-action">
            <label class="mdl-checkbox checkbox--colored-light-blue mdl-js-checkbox mdl-js-ripple-effect" for="list-checkbox-1">
              <input type="checkbox" id="list-checkbox-1" class="mdl-checkbox__input" checked/>
            </label>
          </span>
          <span *ngSwitchCase="1" class="mdl-list__item-secondary-action">
            <label class="mdl-radio radio--colored-light-blue mdl-js-radio mdl-js-ripple-effect" for="list-option-1">
              <input type="radio" id="list-option-1" class="mdl-radio__button" name="options" value="1"/>
            </label>
          </span>
          <span *ngSwitchCase="2" class="mdl-list__item-secondary-action">
            <label class="mdl-switch switch--colored-light-blue mdl-js-switch mdl-js-ripple-effect" for="list-switch-1">
              <input type="checkbox" id="list-switch-1" class="mdl-switch__input" checked/>
            </label>
          </span>
        </li>
      </ul>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--3-col-desktop mdl-cell--6-col-tablet mdl-cell--4-col-phone mdl-cell--top">
  <base-card>
    <base-card-title>
      <h1 class="mdl-card__title-text">Two lines list</h1>
    </base-card-title>
    <base-card-body>
      <ul class="mdl-list">
        <li *ngFor="let item of data; let i = index" class="mdl-list__item mdl-list__item--two-line">
          <span class="mdl-list__item-primary-content">
            <div class="mdl-list__item-avatar">
              <img width="100%" src="assets/images/{{ item.image }}">
            </div>
            <span>{{ item.name }}</span>
            <span class="mdl-list__item-sub-title">{{ item.description }}</span>
          </span>
          <span class="mdl-list__item-secondary-content">
            <span *ngIf="i === 0" class="mdl-list__item-secondary-info">Star</span>
            <a class="mdl-list__item-secondary-action" href="#"><i class="material-icons">star</i></a>
          </span>
        </li>
      </ul>
    </base-card-body>
  </base-card>
</div>
