<table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-shadow--2dp">
  <thead>
    <tr>
      <th class="mdl-data-table__cell--non-numeric" *ngFor="let item of tableHeader">{{ item }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of data" [class.is-selected]="item.isSelected">
      <td class="mdl-data-table__cell--non-numeric">{{ item.project }}</td>
      <td class="mdl-data-table__cell--non-numeric">
        <span *ngFor="let resp of item.responsible"
        class="label label--mini"
        [ngClass]="resp.color">{{ resp.name }}</span>
      </td>
      <td class="mdl-data-table__cell--non-numeric">{{ item.email }}</td>
      <td class="mdl-data-table__cell--non-numeric">{{ item.deadline }}</td>
      <td class="mdl-data-table__cell--non-numeric"
      *ngIf="item.progress < 100">
        <base-progress [progress]="item.progress"></base-progress>
      </td>
      <td class="mdl-data-table__cell--non-numeric task-done"
      *ngIf="item.progress >= 100">
        <i class="material-icons">done</i>
        <span>Complete</span>
      </td>
    </tr>
  </tbody>
</table>
