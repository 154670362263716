<header>{{ title }}</header>
<nav class="mdl-navigation">
  <!--<base-menu-item *ngFor="let item of menu" [data]="item" *appHasPermission="item.allowedRoles"></base-menu-item>

  <div class="mdl-layout-spacer"></div>-->
  <ng-container *ngFor="let item of menu">
    <ng-container *appHasPermission="item.allowedRoles">
      <base-menu-item [data]="item"></base-menu-item>
    </ng-container>
  </ng-container>
  <!--<base-menu-item [data]="{ name: 'GitHub', href: 'https://github.com/CreativeIT/material-angular-dashboard', icon: 'link' }"></base-menu-item>-->
</nav>
