<div class="mdl-cell mdl-cell--12-col">
  <material-angular-select [data]="languages"
                    [name]="'language'"
                    [label]="'Language'"
                    [currentValue]="currentLanguage"></material-angular-select>
</div>

<div class="mdl-cell mdl-cell--12-col">
  <material-angular-select [data]="currencies"
                    [name]="'currency'"
                    [label]="'Currency'"
                    [currentValue]="currentCurrency"></material-angular-select>
</div>

<div class="mdl-cell mdl-cell--12-col">
  <material-angular-select [data]="['Male', 'Female']"
                    [name]="'gender'"
                    [label]="'Gender'"
                    [currentValue]="'Male'"></material-angular-select>
</div>
