<div
  class="mdl-grid mdl-cell mdl-cell--9-col-desktop mdl-cell--12-col-tablet mdl-cell--4-col-phone mdl-cell--top">
  <div class="mdl-cell mdl-cell--4-col-desktop mdl-cell--4-col-tablet mdl-cell--2-col-phone">
    <!--<base-card>
      <base-card-title>
        <h2 class="mdl-card__title-text">My Day</h2>
      </base-card-title>
      <base-card-body>
        <app-pie-chart></app-pie-chart>
      </base-card-body>
    </base-card>-->

    <button id="submit_button"
            class="button--colored-light-blue mdl-button mdl-button--raised mdl-js-button mdl-js-ripple-effect"
    style="position: fixed; height: 10em; width: 20em; font-size: 20px;"
    (click)="goToAttendance()">
      <i class="material-icons">alarm_add</i>
      Asistencia
    </button>
  </div>
  <!--<div class="mdl-cell mdl-cell&#45;&#45;4-col-desktop mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;2-col-phone">
    <app-weather></app-weather>
  </div>-->
  <!--<div class="mdl-cell mdl-cell&#45;&#45;4-col-desktop mdl-cell&#45;&#45;4-col-tablet mdl-cell&#45;&#45;2-col-phone">
    <app-trending></app-trending>
  </div>
  <div class="mdl-cell mdl-cell&#45;&#45;5-col-desktop mdl-cell&#45;&#45;5-col-tablet mdl-cell&#45;&#45;2-col-phone">
    <app-cotoneaster-card></app-cotoneaster-card>
  </div>
  <div class="mdl-cell mdl-cell&#45;&#45;7-col-desktop mdl-cell&#45;&#45;7-col-tablet mdl-cell&#45;&#45;4-col-phone">
    <base-card>
      <base-card-title>
        <h2 class="mdl-card__title-text">Startup Financing Cycle</h2>
      </base-card-title>
      <base-card-body>
        <app-line-chart></app-line-chart>
      </base-card-body>
    </base-card>
  </div>
  <div class="mdl-cell mdl-cell&#45;&#45;12-col-desktop mdl-cell&#45;&#45;12-col-tablet mdl-cell&#45;&#45;4-col-phone ">
    <app-table-card></app-table-card>
  </div>-->
</div>
<!--<div
  class="mdl-grid mdl-cell mdl-cell&#45;&#45;3-col-desktop mdl-cell&#45;&#45;12-col-tablet mdl-cell&#45;&#45;4-col-phone mdl-cell&#45;&#45;top">
  <div class="mdl-cell mdl-cell&#45;&#45;12-col-desktop mdl-cell&#45;&#45;6-col-tablet mdl-cell&#45;&#45;2-col-phone">
    <app-robot-card></app-robot-card>
  </div>
  <div class="mdl-cell mdl-cell&#45;&#45;12-col-desktop mdl-cell&#45;&#45;6-col-tablet mdl-cell&#45;&#45;2-col-phone">
    <app-todo-list></app-todo-list>
  </div>
</div>-->
