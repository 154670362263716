<base-card>
  <base-card-title>
    <h2 class="mdl-card__title-text">Badges</h2>
  </base-card-title>
  <base-card-body>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">TEXT</span>
      </li>
      <li class="mdl-list__item">
        <small class="text-color--gray">Number</small>
      </li>
      <li class="mdl-list__item">
        <span class="mdl-badge" data-badge="4">Inbox</span>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item"></li>
      <li class="mdl-list__item">
        <small class="text-color--gray">Icon</small>
      </li>
      <li class="mdl-list__item">
        <span class="mdl-badge" data-badge="♥">Mood</span>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">ICON</span>
      </li>
      <li class="mdl-list__item">
        <small class="text-color--gray">Number</small>
      </li>
      <li class="mdl-list__item">
        <div class="material-icons mdl-badge mdl-badge--overlap badge--colored-red" data-badge="1">mail_outline</div>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item"></li>
      <li class="mdl-list__item">
        <small class="text-color--gray">Icon</small>
      </li>
      <li class="mdl-list__item">
        <div class="material-icons mdl-badge mdl-badge--overlap badge--colored-red" data-badge="♥">account_circle</div>
      </li>
    </ul>
  </base-card-body>
</base-card>
