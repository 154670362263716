<base-card>
  <base-card-title>
    <h2 class="mdl-card__title-text">Progress bar</h2>
  </base-card-title>
  <base-card-body>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">DEFAULT</span>
      </li>
      <li class="mdl-list__item">
        <base-progress color="red" progress="44"></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="orange" progress="44"></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="light-blue" progress="86"></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="purple" progress="66"></base-progress>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">INDETERMINATE</span>
      </li>
      <li class="mdl-list__item">
        <base-progress color="red" indeterminate></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="orange" indeterminate></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="light-blue" indeterminate></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="purple" indeterminate></base-progress>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">BUFFERING</span>
      </li>
      <li class="mdl-list__item">
        <base-progress color="red" progress="33" buffer="87"></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="orange" progress="60" buffer="60"></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="light-blue" progress="50" buffer="60"></base-progress>
      </li>
      <li class="mdl-list__item">
        <base-progress color="purple" progress="13" buffer="77"></base-progress>
      </li>
    </ul>
  </base-card-body>
</base-card>
