import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth.service';

@Directive({

  selector: '[appHasPermission]',

})
export class HasPermissionDirective {

  private currentRoles: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {
    this.currentRoles = this.authService.getUserAuthorities();
  }

  @Input() set appHasPermission(allowedRoles: string[]) {
    this.viewContainer.clear();
    if (this.currentRoles && allowedRoles.some(role => this.currentRoles.includes(role))) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    // console.log("currentRoles: " + this.currentRoles)
    // console.log("ddd: " + allowedRoles)
  }

}
