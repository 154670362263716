<div class="mdl-cell mdl-cell--3-col-desktop mdl-cell--6-col-tablet mdl-cell--2-col-phone">
  <base-card>
    <base-card-title>
      <h2 class="mdl-card__title-text">Browser Statistics</h2>
    </base-card-title>
    <base-card-body>
      <app-browser-statistics-chart></app-browser-statistics-chart>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--3-col-desktop mdl-cell--6-col-tablet mdl-cell--2-col-phone">
  <base-card>
    <base-card-title>
      <h2 class="mdl-card__title-text">Country Statistics</h2>
    </base-card-title>
    <base-card-body>
      <app-country-statistics-chart></app-country-statistics-chart>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--12-col-tablet mdl-cell--12-col-phone">
  <base-card>
    <base-card-title>
    </base-card-title>
    <base-card-body>
      <app-discrete-bar-chart></app-discrete-bar-chart>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--6-col-tablet mdl-cell--12-col-phone">
  <base-card>
    <base-card-title>
      <h2 class="mdl-card__title-text">Startup Financing Cycle</h2>
    </base-card-title>
    <base-card-body>
      <app-line-chart-1></app-line-chart-1>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--6-col-tablet mdl-cell--12-col-phone">
  <base-card>
    <base-card-title>
      <h2 class="mdl-card__title-text">Startup Financing Cycle</h2>
    </base-card-title>
    <base-card-body>
      <app-line-chart-2></app-line-chart-2>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--6-col-tablet mdl-cell--12-col-phone">
  <base-card>
    <base-card-title>
    </base-card-title>
    <base-card-body>
      <app-line-plus-bar-chart></app-line-plus-bar-chart>
    </base-card-body>
  </base-card>
</div>

<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--6-col-tablet mdl-cell--12-col-phone">
  <base-card>
    <base-card-title>
    </base-card-title>
    <base-card-body>
      <app-stacked-bar-chart></app-stacked-bar-chart>
    </base-card-body>
  </base-card>
</div>
