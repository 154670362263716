
<div *ngIf="notifications.length > 0" class="material-icons mdl-badge mdl-badge--overlap mdl-button--icon notification" id="notification"
     [attr.data-badge]="notifications.length">
  notifications_none
</div>
<ul
  class="mdl-menu mdl-list mdl-js-menu mdl-js-ripple-effect mdl-menu--bottom-right mdl-shadow--2dp notifications-dropdown"
  for="notification">
  <li class="mdl-list__item">
    You have {{ notifications.length }} new notifications!
  </li>
  <li class="mdl-menu__item mdl-list__item list__item--border-top"
      *ngFor="let item of notifications">
    <span class="mdl-list__item-primary-content">
      <span class="mdl-list__item-avatar" ngClass="background-color--{{ item.color }}">
        <i class="material-icons">{{ item.icon }}</i>
      </span>
      <span>{{ item.text }}</span>
    </span>
    <span class="mdl-list__item-secondary-content">
      <span class="label">{{ item.time }}</span>
    </span>
  </li>

  <li class="mdl-list__item list__item--border-top">
    <button href="#" class="mdl-button mdl-js-button mdl-js-ripple-effect">ALL NOTIFICATIONS</button>
  </li>
</ul>
