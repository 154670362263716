import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs/index';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { AuthService } from './auth.service';
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization token for full api requests
    /*if (request.url.includes('api') && this.auth.isLoggedIn) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${this.auth.authToken}` },
      });
    }*/

    const token = this.auth.getToken();

    let authReq = request;
    if (token) {
      authReq = request.clone({
        setHeaders: { Authorization: `Bearer ${this.auth.authToken}` }
      });
    }

    // return next.handle(request);
    return next.handle(request).pipe(
      catchError( (error: HttpErrorResponse) => {
        if (error.status == 401) {
          this.auth.logout();
          this.router.navigate(['/pages/login']);
        }
        return throwError(error);
      } )
    );
  }
}
