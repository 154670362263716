<base-card>
  <base-card-title>
    <h2 class="mdl-card__title-text">Chips</h2>
  </base-card-title>
  <base-card-body>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">BASIC CHIP</span>
      </li>
      <li class="mdl-list__item">
        <span class="mdl-chip">
          <span class="mdl-chip__text">Basic Chip</span>
        </span>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">DELETABLE CHIP</span>
      </li>
      <li class="mdl-list__item">
        <span class="mdl-chip mdl-chip--deletable">
          <span class="mdl-chip__text">Deletable Chip</span>
          <button type="button" class="mdl-chip__action"><i class="material-icons">cancel</i></button>
        </span>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">BUTTON CHIP</span>
      </li>
      <li class="mdl-list__item">
        <button type="button" class="mdl-chip color--teal mdl-color-text--white">
          <span class="mdl-chip__text">Button Chip</span>
        </button>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">CONTACT CHIP</span>
      </li>
      <li class="mdl-list__item">
         <span class="mdl-chip mdl-chip--contact">
           <span class="mdl-chip__contact color--orange">A</span>
           <span class="mdl-chip__text">Contact Chip</span>
         </span>
      </li>
    </ul>
    <ul class="mdl-list pull-left">
      <li class="mdl-list__item">
        <span class="text-color--gray">DELETABLE CONTACT CHIP</span>
      </li>
      <li class="mdl-list__item">
        <span class="mdl-chip mdl-chip--contact mdl-chip--deletable">
          <img class="mdl-chip__contact" src="/assets/images/Icon_header.png">
          <span class="mdl-chip__text">Luke Skywalker</span>
          <a href="#" class="mdl-chip__action"><i class="material-icons">cancel</i></a>
        </span>
      </li>
    </ul>
  </base-card-body>
</base-card>
