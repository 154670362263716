import {HostBinding, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";

@Injectable()
export class WeatherService {
  @HostBinding('class.weather') public readonly weather = true;

  private apiKey = '246bb99cf0837bca580afb154a2e5dce'; // Replace with your API key
  //private apiUrl = `http://api.openweathermap.org/data/2.5/weather?appid=${this.apiKey}&units=metric`;
  private apiUrl = `http://api.openweathermap.org/data/2.5/weather?q=Los Polvorines,ar&APPID=b8223b67bcc5ff9252237dcbc3c91d48&units=metric`;
  constructor(private http: HttpClient) {}

  getWeather(city: string): Observable<any> {
    const url = `${this.apiUrl}&q=${city}`;
    return this.http.get(url).pipe(
      map(response => response)
    );
  }


}
