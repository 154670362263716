<base-card>
  <base-card-title>
    <h2 class="mdl-card__title-text">Trending</h2>
  </base-card-title>
  <base-card-body>
    <ul class="mdl-list">
    <li class="mdl-list__item">
      <span class="mdl-list__item-primary-content list__item-text">UX</span>
      <span class="mdl-list__item-secondary-content">
        <i class="material-icons trending__arrow-up" role="presentation">arrow_drop_up</i>
             </span>
      <span class="mdl-list__item-secondary-content trending__percent">1 %</span>
    </li>
    <li class="mdl-list__item list__item--border-top">
      <span class="mdl-list__item-primary-content list__item-text">PHP</span>
      <span class="mdl-list__item-secondary-content">
               <i class="material-icons trending__arrow-down" role="presentation">arrow_drop_down</i>
             </span>
      <span class="mdl-list__item-secondary-content trending__percent">2 %</span>
    </li>
    <li class="mdl-list__item list__item--border-top">
      <span class="mdl-list__item-primary-content list__item-text ">Big Data</span>
      <span class="mdl-list__item-secondary-content">
               <i class="material-icons trending__arrow-up" role="presentation">arrow_drop_up</i>
             </span>
      <span class="mdl-list__item-secondary-content trending__percent">5 %</span>
    </li>
    <li class="mdl-list__item list__item--border-top">
      <span class="mdl-list__item-primary-content list__item-text">Material Design</span>
      <span class="mdl-list__item-secondary-content">
               <i class="material-icons trending__arrow-up" role="presentation">arrow_drop_up</i>
             </span>
      <span class="mdl-list__item-secondary-content trending__percent">18 %</span>
    </li>
    <li class="mdl-list__item list__item--border-top">
      <span class="mdl-list__item-primary-content">JavaScript</span>
      <span class="mdl-list__item-secondary-content">
               <i class="material-icons trending__arrow-up" role="presentation">arrow_drop_up</i>
             </span>
      <span class="mdl-list__item-secondary-content trending__percent">17 %</span>
    </li>
  </ul>
  </base-card-body>
</base-card>
