<base-card>
  <base-card-title>
    <h2 class="mdl-card__title-text">To-do list</h2>
  </base-card-title>
  <base-card-body>
    <ul class="mdl-list">
      <li *ngFor="let item of items" class="mdl-list__item" #listItem>
        <span class="mdl-list__item-primary-content">
          <label [for]="item.id"
                 class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                 [class.is-checked]="item.completed"
                 checkboxItem>
            <input type="checkbox" class="mdl-checkbox__input" [id]="item.id" [checked]="item.completed" (change)="item.completed = !item.completed"/>
            <span class="mdl-checkbox__label">{{ item.title }}</span>
          </label>
        </span>
        <div class="mdl-list__item-secondary-content">
          <button [attr.for]="item.id" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored mdl-button--mini-icon pull-right"
                  deleteItem
                  (click)="deleteItem(item)">
            <i class="material-icons">clear</i>
          </button>
        </div>
      </li>
      <li *ngIf="this.createdItem !== null" class="mdl-list__item" #listItem>
        <span class="mdl-list__item-primary-content">
          <label for="input"
                 class="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                 [class.is-checked]="this.createdItem.completed"
                 checkboxItem>
            <input type="checkbox" class="mdl-checkbox__input" id="input" [(ngModel)]="this.createdItem.completed"/>
            <span class="mdl-checkbox__label">
              <div class="mdl-textfield mdl-js-textfield">
                <input class="mdl-textfield__input" type="text" id="todoInput" #todoInput (keydown.escape)="this.createdItem = null" (keydown.enter)="this.addItem()" [(ngModel)]="this.createdItem.title"/>
                <label class="mdl-textfield__label" for="todoInput">What to do?..</label>
              </div>
            </span>
          </label>
        </span>
        <div class="mdl-list__item-secondary-content">
          <button attr.for="input" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored mdl-button--mini-icon pull-right"
                  deleteItem
                  (click)="createdItem = null">
            <i class="material-icons">clear</i>
          </button>
        </div>
      </li>
    </ul>
  </base-card-body>
  <base-card-actions>
    <button class="mdl-button mdl-js-button mdl-js-ripple-effect" (click)="deleteCompletedItems()" [disabled]="createdItem !== null">remove selected</button>
    <button
      class="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--fab mdl-shadow--8dp mdl-button--colored"
      [disabled]="createdItem !== null"
      (click)="createItem()">
      <i class="material-icons mdl-js-ripple-effect">add</i>
    </button>
  </base-card-actions>
</base-card>
