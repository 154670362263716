import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {TimeTrackingDTO} from "../dto/TimeTrackingDTO";
import * as moment from 'moment';
import {jwtDecode} from "jwt-decode";
import {Router} from "@angular/router";
import {User} from "../dto/User";
import {Personal} from "../dto/Personal";
import {Movements} from "../dto/Movements";
import {TimeTrackingReportDTO} from "../dto/TimeTrackingReportDTO";

const tokenName = 'token';

export interface LoginResponse {
  access_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private isLogged$ = new BehaviorSubject(false);
  private url = `${environment.apiBaseUrl}/api`;
  private user = {
    additional_info: '',
    email: '',
    id: '',
    lastname: '',
    name: ''
  }; // some data about user

  private headers = {}
  private token: string | null = null;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  setToken(token: string): void {
    this.token = token;
    localStorage.setItem(tokenName, token);
  }

  getToken(): string | null {
    if (!this.token) {
      this.token = localStorage.getItem('token');
    }
    return this.token;
  }

  getBasicAuthHeaders(username: string, password: string): HttpHeaders {
    const base64Credentials = btoa(`${username}:${password}`);
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${base64Credentials}`
    });
  }

  getOAuthHeaders(): HttpHeaders {
    const token = localStorage.getItem(tokenName)//this.getToken();
    return new HttpHeaders({
      'Authorization': `Bearer ` + localStorage.getItem(tokenName),
      'Content-Type': 'application/json'
    });
  }


  public get isLoggedIn(): boolean {
    return this.isLogged$.value;
  }

  async login(data): Promise<any> {
    const headers = this.getBasicAuthHeaders('bvma129','XQO7PMhsQrwCAkj7o');

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', data.username)
      .set('password', data.password);

    return this.http.post<LoginResponse>(`${this.url}/login`, body.toString(), { headers })
      .toPromise().then(response => {
          const token = response.access_token;
          const decodedToken = jwtDecode(token);
          localStorage.setItem('currentUser', JSON.stringify(decodedToken));
          localStorage.setItem(tokenName, token);
          this.currentUserSubject.next(decodedToken);
          return decodedToken;
        }).catch(this.handleError<any>('login', []));
        /*map((res: { id: any, access_token: string, refresh_token: string, additional_info: string,
          email: string, lastname: string, name: string}) => {
          this.user.additional_info = res.additional_info;
          this.user.email = res.email;
          this.user.id = res.id;
          this.user.lastname = res.lastname;
          this.user.name = res.name;
          this.setToken(res.access_token);
          const decodedToken = jwtDecode( localStorage.getItem(tokenName) );
          localStorage.setItem('currentUser', JSON.stringify(decodedToken));

          //localStorage.setItem(tokenName, res.access_token);
          // only for example
          localStorage.setItem('id', res.id);
          localStorage.setItem('email', res.email);
          // this.isLogged$.next(true);
          // this.isLogged$.next(decodedToken);
          this.currentUserSubject.next(decodedToken);
          // return this.user;
          return decodedToken;
        })).catch(this.handleError<any>('login', []));*/
  }

  /*public logout() {
    return this.http.get(`${this.url}/logout`)
      .pipe(map((data) => {
        localStorage.clear();
        this.user = null;
        this.isLogged$.next(false);
        return of(false);
      }));
  }*/

  logout(): void {
    this.token = null;
    localStorage.removeItem(tokenName);
    localStorage.removeItem('id');
    localStorage.removeItem('email');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('username');
    this.router.navigate(['/pages/login']);
  }

  public signup(data) {
    return this.http.post(`${this.url}/signup`, data)
      .pipe(
        map((res: { user: any, token: string }) => {
          this.user = res.user;

          localStorage.setItem(tokenName, res.token);
          const decodedToken = jwtDecode( localStorage.getItem(tokenName) );
          localStorage.setItem('currentUser', JSON.stringify(decodedToken));
          // only for example
          localStorage.setItem('id', res.user.id);
          localStorage.setItem('email', res.user.email);
          this.isLogged$.next(true);
          return this.user;
        }));
  }

  getCurrentUser(): any {
    return this.currentUserSubject.value;
  }

  getUserAuthorities(): string[] {
    const currentUser = this.getCurrentUser();
    return currentUser ? currentUser.authorities : [];
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  public get authToken(): string {
    return localStorage.getItem(tokenName);
  }

  public get userData(): Observable<any> {
    // send current user or load data from backend using token
    return this.loadUser();
  }

  private loadUser(): Observable<any> {
    // use request to load user data with token
    // it's fake and useing only for example
    if (localStorage.getItem('id') && localStorage.getItem('email')) {
      this.user = {
        id: localStorage.getItem('id'),
        email: localStorage.getItem('email'),
        additional_info: localStorage.getItem('additional_info'),
        lastname: localStorage.getItem('lastname'),
        name: localStorage.getItem('name'),
      };
    }
    return of(this.user);
  }

  getList(): Observable<any> {
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/personal/all`, { headers });
  }

  timeTrackingSave(timeTrackDTO : TimeTrackingDTO): Observable<any> {
    const headers = this.getOAuthHeaders();
    return this.http.post(`${this.url}/time/track`, timeTrackDTO, { headers });
  }

  //Obtengo el personal que esta en el cuartel separado por descatamento
  listPersonalInTheStationFilteringByDetachment(): Observable<any> {
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/time/detachment`, { headers });
  }

  //Obtengo el personal que estuvo en el cuartel durante el dia
  listPersonalInTheDate(): Observable<any> {
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/time/date`, { headers });
  }

  getListPersonalByStation(): Observable<any> {
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/personal/list/station`, { headers });
  }

  getListUsersByStation(): Observable<any> {
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/users/all`, { headers });
  }

  activeDeactivate(id : number, status: boolean): Observable<any> {
    const headers = this.getOAuthHeaders();
    if (status == true){
      return this.http.delete(`${this.url}/users/disabled/` + id, { headers });
    } else {
      return this.http.delete(`${this.url}/users/enabled/` + id, { headers });
    }

  }

  getUserById(id: number): Observable<any> {
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/users/` + id, { headers });
  }

  saveUser(user: User) {
    const headers = this.getOAuthHeaders();
    return this.http.post(`${this.url}/users/`, user, { headers });
  }

  getListPersonal(filter: string){
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/personal/all/`+ filter, { headers });
  }

  getPersonalById(id: number) {
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/personal/` + id, { headers });
  }

  savePersonal(personal: Personal) {
    const headers = this.getOAuthHeaders();
    return this.http.post(`${this.url}/personal`, personal, { headers });
  }

  getListSanctions(){
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/sanctions`, { headers });
  }

  saveSanction(sanctions: Movements){
    const headers = this.getOAuthHeaders();
    return this.http.post(`${this.url}/sanction`, sanctions, { headers });
  }

  saveMovements(movements: Movements){
    const headers = this.getOAuthHeaders();
    return this.http.post(`${this.url}/personal/movements`, movements, { headers });
  }

  getListHollydays(filter: string){
    const headers = this.getOAuthHeaders();
    return this.http.get(`${this.url}/hollydays/` + filter, { headers });
  }

  saveHollydays(hollydays: Movements){
    const headers = this.getOAuthHeaders();
    return this.http.post(`${this.url}/hollydays`, hollydays, { headers });
  }

  getTimeTrackingData(year: number, month: number): Observable<any> {
    const headers = this.getOAuthHeaders();
    return this.http.get<any>(`${this.url}/time/report/${month}/${year}`, { headers }).pipe(
      map(response => {
        const data = new Map<string, Map<string, number>>();

        for (const dateStr in response) {
          const date = moment(dateStr, 'YYYY-MM-DD').toDate();
          const dailyData = response[dateStr];
          for (const person in dailyData) {
            if (!data.has(person)) {
              data.set(person, new Map<string, number>());
            }
            data.get(person).set(moment(date).format('D'), dailyData[person]);
          }
        }

        // Sort the outer map by the key (which is "number-name")
        const sortedData = new Map<string, Map<string, number>>(
          Array.from(data.entries()).sort((a, b) => {
            const numA = parseInt(a[0].split('-')[0], 10);
            const numB = parseInt(b[0].split('-')[0], 10);
            return numA - numB;
          })
        );

        // Optionally sort the inner maps by date keys (already formatted as 'D' which are numeric strings)
        sortedData.forEach((value, key) => {
          const sortedInnerMap = new Map<string, number>(
            Array.from(value.entries()).sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
          );
          sortedData.set(key, sortedInnerMap);
        });

        return sortedData;
      })
    );
  }

  getTimeTrackingDataNew(year: number, month: number): Observable<Map<string, Map<string, TimeTrackingReportDTO>>> {
    const headers = this.getOAuthHeaders();
    return this.http.get<any>(`${this.url}/time/report/${month}/${year}`, { headers }).pipe(
      map(response => {
        const data = new Map<string, Map<string, TimeTrackingReportDTO>>();

        for (const dateStr in response) {
          const date = moment(dateStr, 'YYYY-MM-DD').toDate();
          const dailyData = response[dateStr];
          for (const person in dailyData) {
            if (!data.has(person)) {
              data.set(person, new Map<string, TimeTrackingReportDTO>());
            }
            const timeTracking = dailyData[person];
            const timeTrackingDTO: TimeTrackingReportDTO = {
              time: timeTracking.time,
              flag: timeTracking.flag
            };
            data.get(person).set(moment(date).format('D'), timeTrackingDTO);
          }
        }

        // Ordenar el Map externo por la clave (que es "number-name")
        const sortedData = new Map<string, Map<string, TimeTrackingReportDTO>>(
          Array.from(data.entries()).sort((a, b) => {
            const numA = parseInt(a[0].split('-')[0], 10);
            const numB = parseInt(b[0].split('-')[0], 10);
            return numA - numB;
          })
        );

        // Opcionalmente ordenar los Maps internos por las claves de fecha (ya formateadas como 'D')
        sortedData.forEach((value, key) => {
          const sortedInnerMap = new Map<string, TimeTrackingReportDTO>(
            Array.from(value.entries()).sort((a, b) => parseInt(a[0], 10) - parseInt(b[0], 10))
          );
          sortedData.set(key, sortedInnerMap);
        });

        return sortedData;
      })
    );
  }

}
