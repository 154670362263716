<base-card>
  <base-card-title>
    <h2 class="mdl-card__title-text">Ahora</h2>
    <div class="mdl-layout-spacer"></div>
    <div class="mdl-card__subtitle-text">
      <i class="material-icons">room</i>
      Los Polvorines, Buenos Aires
    </div>
  </base-card-title>
  <base-card-body expanded>
    <p class="weather-temperature">{{ weatherData.main.temp }}<sup>&deg;</sup></p>
    <p class="weather-description">
      {{ weatherData.weather[0].description }}
    </p>
  </base-card-body>
</base-card>
