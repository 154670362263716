<base-card>
  <base-card-title expanded>
    <h2 class="mdl-card__title-text">BB-8</h2>
  </base-card-title>
  <base-card-body>
    <div>
      A skittish but loyal astromech, BB-8 accompanied Poe Dameron on many missions for the
      Resistance helping keep his X-wing in working order.
    </div>
    <a href="http://www.starwars.com/databank/bb-8" target="_blank">Star Wars Databank</a>
  </base-card-body>
</base-card>
