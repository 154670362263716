<div class="mdl-cell mdl-cell--6-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone">
  <base-card class="map">
    <div class="curtain"></div>
    <div class="mdl-card--expand map__window">

    </div>
    <div class="mdl-card__supporting-text">
      <div class="pull-right">
        <div class="map__target-image">
          <div class="map__target-picture"></div>
        </div>
      </div>
      <div class="map__target-info">
        <h5 class="map__target-title"></h5>
        <div class="map__target-location"></div>
        <div class="map__target-opening-hours"></div>
        <div class="map__target-description"></div>
      </div>
    </div>
  </base-card>
</div>
