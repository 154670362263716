<base-card>
  <base-card-title expanded>
    <h2 class="mdl-card__title-text">Cotoneaster</h2>
  </base-card-title>
  <base-card-body>
    <div>
      Cotoneaster is a genus of flowering plants in the rose family, Roseaceae, netive to the
      Palaearctic region, with a strong concentration of diversity in the genus in the
      mountains
      of southwestern China and the Himalayas.
    </div>
    <a href="https://en.wikipedia.org/wiki/Cotoneaster" target="_blank">Wikipedia</a>
  </base-card-body>
</base-card>
